<template>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title>Closures (days with no class)</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="selectedClosures.length > 0" text @click="removeSelectedClosures">
        <v-icon left>fal fa-trash</v-icon>
        Remove Selected
      </v-btn>
      <v-dialog v-model="addClosureDialog" width="450">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text @click="clearClosureEditForm()">
            <v-icon left>fal fa-plus</v-icon>
            Add Closure
          </v-btn>
        </template>
        <v-card>
          <v-card-title>{{ selectedIndex >= 0 ? 'Edit' : 'Add' }} Closure</v-card-title>
          <v-card-text>
            <p>Add a period to the selected term where there are no classes. Examples are for day of prayer, breaks, snow days, etc.</p>
            <p>If you are adding a single day (i.e. day of prayer), then the end date as well as the start and end times are not needed. If you want to cover a partial day, then use the start/end times to bracket the times to be closed. Classes that start during the given window will be marked as cancelled; classes that start before and end during will show as normal.</p>
            <v-form v-model="addFormValid" ref="addForm">
              <v-text-field label="Description" v-model="addLabel" outlined :rules="required"></v-text-field>
              <v-row>
                <v-col cols="6">
                  <date-picker label="Start Date" v-model="addStartDate" :rules="required" :min-date="termStart" :max-date="termEnd"></date-picker>
                </v-col>
                <v-col cols="6">
                  <time-picker label="Start Time (optional)" v-model="addStartTime" :clear-button="true" hint="Defaults to 12:00 am" persistent-hint></time-picker>
                </v-col>
                <v-col cols="6">
                  <date-picker label="End Date (optional)" v-model="addEndDate" :clear-button="true" :min-date="addStartDate ? addStartDate : termStart" :max-date="termEnd"></date-picker>
                </v-col>
                <v-col cols="6">
                  <time-picker label="End Time (optional)" v-model="addEndTime" :clear-button="true"></time-picker>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="selectedIndex = -1; addClosureDialog = false">Cancel</v-btn>
            <v-btn text color="success" @click="addClosure">{{ selectedIndex >= 0 ? 'Save' : 'Add' }} Closure</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-card-text>
      <p>These are days (or partial days) when classes are not meeting. This can be for Day of Prayer, breaks, holidays, snow days, reading days, finals, etc.</p>
    </v-card-text>
    <v-data-table v-model="selectedClosures" :items="closures" :headers="headers" :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }" item-key="_id" show-select>
      <template v-slot:item.start="{ item }">
        {{ stringFormatDate(item.start) }}
      </template>
      <template v-slot:item.end="{ item }">
        {{ item.end ? stringFormatDate(item.end) : '' }}
      </template>
      <template v-slot:item.actions="{ item, index }">
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon>
              <v-icon>fal fa-ellipsis-v</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="editClosure(index)">
              <v-list-item-title>Edit Closure</v-list-item-title>
            </v-list-item>
            <v-list-item @click="removeClosure(index)">
              <v-list-item-title>Remove Closure</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import { stringFormatDate } from '../../../helpers/formatters'
export default {
  props: {
    termId: {
      type: String,
      required: true
    },
    termStart: {
      type: String,
      required: true
    },
    termEnd: {
      type: String,
      required: true
    }
  },
  components: {
    TimePicker: () => import('@/components/forms/inputs/TimePicker'),
    DatePicker: () => import('@/components/forms/inputs/DatePicker')
  },
  setup (props, { root }) {
    const closures = ref([])
    watch(() => props.termId, () => {
      if (props.termId != null && props.termId !== '') {
        root.$feathers.service('system/term').get(props.termId).then((data) => {
          closures.value = data.closures
        })
      }
    })

    const headers = ref([
      { text: 'Description', value: 'label' },
      { text: 'Closure Date/Time', value: 'start' },
      { text: 'End Date/Time', value: 'end' },
      { text: 'Actions', value: 'actions' }
    ])

    const selectedClosures = ref([])
    const addClosureDialog = ref(false)
    const addForm = ref(null)
    const addFormValid = ref(false)
    const addLabel = ref('')
    const addStartDate = ref(null)
    const addStartTime = ref(null)
    const addEndDate = ref(null)
    const addEndTime = ref(null)
    function addClosure () {
      if (addForm.value.validate()) {
        let obj = {
          label: addLabel.value,
          start: new Date(addStartDate.value + ' ' + (addStartTime.value ? addStartTime.value : '00:00:00')),
          end: new Date((addEndDate.value ? addEndDate.value : addStartDate.value) + ' ' + (addEndTime.value ? addEndTime.value : '23:59:59'))
        }
        if (selectedIndex.value == null || selectedIndex.value < 0) {
          root.$feathers.service('system/term').patch(props.termId, { $push: { closures: obj } }).then((data) => {
            closures.value = data.closures
          })
        } else {
          closures.value.splice(selectedIndex.value, 1, obj)
          root.$feathers.service('system/term').patch(props.termId, { ['closures.' + selectedIndex.value]: obj })
        }
        addLabel.value = ''
        addStartDate.value = ''
        addStartTime.value = null
        addEndDate.value = ''
        addEndTime.value = null
        addClosureDialog.value = false
      }
    }
    const selectedIndex = ref(-1)
    function editClosure (index) {
      const { label, start, end } = closures.value[index]
      addLabel.value = label
      let startDate = new Date(start)
      let month = startDate.getMonth() + 1
      addStartDate.value = startDate.getFullYear() + '-' + (month < 10 ? '0' : '') + month + '-' + (startDate.getDate() < 10 ? '0' : '') + startDate.getDate()
      if (startDate.getHours() === 0 && startDate.getMinutes() === 0) {
        addStartTime.value = null
      } else {
        addStartTime.value = (startDate.getHours() < 10 ? '0' : '') + startDate.getHours() + ':' + (startDate.getMinutes() < 10 ? '0' : '') + startDate.getMinutes()
      }

      let endDate = new Date(end)
      month = endDate.getMonth() + 1
      addEndDate.value = endDate.getFullYear() + '-' + (month < 10 ? '0' : '') + month + '-' + (endDate.getDate() < 10 ? '0' : '') + endDate.getDate()
      if (endDate.getHours() === 11 && endDate.getMinutes() === 59) {
        addEndTime.value = null
      } else {
        addEndTime.value = (endDate.getHours() < 10 ? '0' : '') + endDate.getHours() + ':' + (endDate.getMinutes() < 10 ? '0' : '') + endDate.getMinutes()
      }

      selectedIndex.value = index
      addClosureDialog.value = true
    }

    function removeClosure (index) {
      if (confirm('Please confirm that you want to delete this closure from this term?')) {
        closures.value.splice(index, 1)
        root.$feathers.service('system/term').patch(props.termId, { closures: closures.value })
      }
    }
    function clearClosureEditForm () {
      selectedIndex.value = null
      addLabel.value = ''
      addStartDate.value = ''
      addStartTime.value = ''
      addEndDate.value = ''
      addEndTime.value = ''
    }

    function removeSelectedClosures () {
      if (confirm('Please confirm that you want to delete ' + (selectedClosures.value.length === 1 ? 'this closure' : 'these closures') + ' from this term?')) {
        let arr = []
        for (let i = 0; i < closures.value.length; i++) {
          let selected = false
          for (let j = 0; j < selectedClosures.value.length; j++) {
            if (selectedClosures.value[j]._id === closures.value[i]._id) {
              selected = true
              break
            }
          }
          if (!selected) arr.push(closures.value[i])
        }
        root.$feathers.service('system/term').patch(props.termId, { closures: arr }).then((data) => {
          closures.value = data.closures
          selectedClosures.value = []
        })
      }
    }

    const required = ref([v => !!v || 'Required'])

    return {
      closures,
      headers,
      selectedClosures,
      addClosureDialog,
      addForm,
      addFormValid,
      addLabel,
      addStartDate,
      addStartTime,
      addEndDate,
      addEndTime,
      addClosure,
      selectedIndex,
      editClosure,
      removeClosure,
      clearClosureEditForm,
      removeSelectedClosures,
      required,
      stringFormatDate
    }
  }
}
</script>
